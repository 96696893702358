.quiz-history-page {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: var(--transition);
}

.page-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.page-header h1 {
    margin: 0;
    color: var(--text-color);
    font-size: 24px;
    font-weight: 500;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 8px 16px;
    border: none;
    background-color: var(--secondary-color);
    color: var(--text-color);
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-right: 1rem;
    transition: var(--transition);
    font-size: 14px;
    font-weight: 500;
}

.back-button:hover {
    background-color: var(--sidebar-hover-color);
    transform: translateY(-1px);
}

.quiz-history-container {
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;
    border: 1px solid var(--border-color);
}

.quiz-history-table {
    width: 100%;
    border-collapse: collapse;
}

.quiz-history-table th,
.quiz-history-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-color);
}

.quiz-history-table th {
    background-color: var(--secondary-color);
    font-weight: 500;
    color: var(--text-color);
}

.quiz-history-table tbody tr:hover {
    background-color: var(--sidebar-hover-color);
}

.loading-spinner {
    text-align: center;
    padding: 2rem;
    color: var(--light-text-color);
}

.loading-spinner .spinner {
    border: 4px solid var(--border-color);
    border-top: 4px solid var(--primary-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    text-align: center;
    padding: 2rem;
    color: var(--error-color);
}

.error-message button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
    font-size: 14px;
    font-weight: 500;
    margin-top: 1rem;
}

.error-message button:hover {
    background-color: var(--primary-color-dark);
    transform: translateY(-1px);
}

.no-history-message {
    text-align: center;
    padding: 2rem;
    color: var(--light-text-color);
}

/* Dark mode specific styles */
.dark-mode .quiz-history-table th {
    background-color: var(--sidebar-background);
}

.dark-mode .quiz-history-container {
    background-color: var(--sidebar-background);
}

.dark-mode .back-button {
    background-color: var(--sidebar-background);
}

.dark-mode .back-button:hover {
    background-color: var(--sidebar-hover-color);
}