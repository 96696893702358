.quiz-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.page-title {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 30px;
}

.setup-container {
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.wordlist-selection, .quiz-options {
    margin-bottom: 30px;
}

.wordlist-selection h2, .quiz-options h2 {
    font-size: 1.2em;
    color: var(--primary-color);
    margin-bottom: 15px;
}

.wordlist-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
}

.wordlist-item {
    background-color: var(--secondary-color);
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.wordlist-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wordlist-item.selected {
    border-color: var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
}

.word-count {
    display: block;
    font-size: 0.8em;
    color: var(--light-text-color);
    margin-top: 5px;
}

.quiz-type-selection {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.quiz-type-selection label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.quiz-type-selection input[type="radio"] {
    margin-right: 5px;
}

.quiz-length-selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quiz-length-selection label {
    margin-right: 10px;
}

.quiz-length-selection select {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.start-quiz-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.start-quiz-btn:hover {
    background-color: var(--primary-color-dark);
}

/* 퀴즈 진행 중 UI 스타일 (기존 스타일 유지) */