.login-button {
    display: flex;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-details {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-name {
    font-weight: bold;
    margin-right: 10px;
    color: var(--text-color);
    order: 2; /* 이름을 칭호 뒤로 배치 */
}

.title-badge {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    order: 1; /* 칭호를 이름 앞으로 배치 */
}

.logout-btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logout-btn:hover {
    background-color: var(--primary-color-dark);
}

/* Google 로그인 버튼 스타일 */
.google-btn {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    color: white;
    border: none;
    padding: 1px;
    border-radius: 2px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s;
}

.google-btn:hover {
    background-color: #357ae8;
}

.google-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    margin-right: 1px;
}

.google-icon {
    width: 18px;
    height: 18px;
}

.btn-text {
    padding: 10px 10px 10px 0;
    font-size: 14px;
    font-weight: bold;
}

.title-badge.expert {
    background-color: #9333ea;
}

.title-badge.advanced {
    background-color: #3b82f6;
}

.title-badge.intermediate {
    background-color: #22c55e;
}

.title-badge.beginner {
    background-color: #eab308;
}

.title-badge.newcomer {
    background-color: #6b7280;
}