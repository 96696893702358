:root {
    --sidebar-width: 220px;
    --header-height: 60px;
    --primary-color: #4a90e2;
    --primary-color-dark: #3a7bc2;
    --secondary-color: #f0f4f8;
    --text-color: #333333;
    --light-text-color: #757575;
    --background-color: #ffffff;
    --sidebar-background: #f8f9fa;
    --sidebar-text-color: #495057;
    --sidebar-hover-color: #e9ecef;
    --border-color: #e0e0e0;
    --success-color: #4caf50;
    --error-color: #f44336;
    --primary-color-rgb: 74, 144, 226;
    --box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    --border-radius: 8px;
    --transition: all 0.3s ease;

}

.dark-mode {
    --primary-color: #61dafb;
    --primary-color-dark: #4facd9;
    --secondary-color: #2c2c2c;
    --text-color: #ffffff;
    --light-text-color: #b0b0b0;
    --background-color: #1a1a1a;
    --sidebar-background: #2c2c2c;
    --sidebar-text-color: #e0e0e0;
    --sidebar-hover-color: #3c3c3c;
    --border-color: #444444;
    --primary-color-rgb: 97, 218, 251;
}

body {
    font-family: 'Noto Sans KR', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.app-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.app-body {
    display: flex;
    flex: 1;
}

.main-content {
    flex-grow: 1;
    padding: 24px;
    overflow-y: auto;
}

.button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 8px 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

input[type="text"], select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    color: var(--text-color);
    transition: var(--transition);
    font-size: 14px;
}

input[type="text"]:focus, select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}
.app-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--sidebar-background);
    border-bottom: 1px solid var(--border-color);
}

.login-container {
    display: flex;
    align-items: center;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}