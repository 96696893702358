.edit-word-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.word-list {
    margin-bottom: 20px;
}

.word-item {
    display: flex;
    margin-bottom: 10px;
}

.word-item input {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.delete-btn, .add-btn, .save-btn, .cancel-btn {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.delete-btn {
    background-color: var(--error-color);
    color: white;
}

.add-btn {
    background-color: var(--success-color);
    color: white;
}

.save-btn {
    background-color: var(--primary-color);
    color: white;
}

.cancel-btn {
    background-color: var(--secondary-color);
    color: var(--text-color);
}

.add-word {
    display: flex;
    margin-bottom: 20px;
}

.add-word input {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

@media (max-width: 600px) {
    .word-item, .add-word {
        flex-direction: column;
    }

    .word-item input, .add-word input {
        margin-bottom: 10px;
    }
}