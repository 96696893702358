:root {
    --primary-color: #2563eb;
    --secondary-color: #f59e0b;
    --success-color: #10b981;
    --error-color: #ef4444;
    --text-color: #374151;
    --background-color: #ffffff;
}

.flashcard-view {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.flashcard-title {
    color: var(--primary-color);
    margin-bottom: 10px;
}

.flashcard-container {
    perspective: 1000px;
    position: relative;
    min-height: 300px; /* 기본 최소 높이 */
}

.flashcard {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: inherit; /* 컨테이너의 최소 높이를 상속 */
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
    margin-bottom: 20px; /* 카드와 버튼 사이 간격 */
}

.flashcard.flipped {
    transform: rotateY(180deg);
}

.flashcard-front, .flashcard-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.flashcard-front {
    background-color: var(--background-color);
    border: 2px solid var(--primary-color);
}

.flashcard-back {
    background-color: var(--primary-color);
    color: white;
    transform: rotateY(180deg);
    flex-direction: column;
    padding: 20px;
}

.meaning-section,
.example-section {
    width: 100%;
    text-align: left;
}

.meaning-section {
    margin-bottom: 20px;
}

.meaning-section h3,
.example-section h3 {
    font-size: 0.8em;
    margin-bottom: 5px;
    opacity: 0.8;
}

.meaning-section p,
.example-section p {
    margin: 0;
}

.meaning-section p {
    font-size: 1.2em;
}

.example-section p {
    font-size: 0.9em;
    opacity: 0.9;
}

.flashcard-tooltip {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--primary-color);
    font-size: 18px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: var(--primary-color);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
}

.flashcard-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.flashcard:hover .flashcard-tooltip {
    opacity: 1;
}

/* 버튼 컨트롤 영역 수정 */
.flashcard-controls {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
    background-color: transparent; /* 배경 제거 */
    box-shadow: none; /* 그림자 제거 */
    margin-top: 10px; /* 카드와의 간격 */
}

.control-btn {
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
}

.control-btn:hover {
    opacity: 0.8;
    transform: translateY(-2px);
}

.previous-btn, .next-btn {
    background-color: var(--primary-color);
    color: white;
}

.restart-btn {
    background-color: var(--secondary-color);
    color: var(--text-color);
}

.speak-btn {
    background-color: var(--success-color);
    color: white;
}

.speak-btn.speaking {
    background-color: var(--error-color);
}

.quit-btn {
    background-color: var(--error-color);
    color: white;
}

.progress-text {
    font-size: 14px;
    color: var(--text-color);
    margin-top: 10px;
}

.loading {
    text-align: center;
    color: var(--text-color);
    font-size: 18px;
    margin-top: 20px;
}

@media (max-width: 600px) {
    .flashcard-container {
        min-height: 250px;
    }

    .flashcard-front, .flashcard-back {
        font-size: 20px;
    }

    .meaning-section h3,
    .example-section h3 {
        font-size: 0.7em;
    }

    .meaning-section p {
        font-size: 1em;
    }

    .example-section p {
        font-size: 0.8em;
    }

    .control-btn {
        padding: 8px 12px;
        font-size: 12px;
        flex: 1 1 calc(50% - 8px); /* 2열 배치 */
        justify-content: center;
    }

    .flashcard-controls {
        gap: 8px;
    }
}